.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(./bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
body {
  display: block;
  margin: 0px;
}
.lContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.lInput{
  height: 30px;
  padding: 10px;
}

.lButton {
  border: none;
  padding: 10px 20px;
  background-color: #0071c2;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}

.lButton:disabled{
  background-color: #0071c28c;
  cursor: not-allowed;
}