.chapterdetails {
  display: flex;
  flex-direction: column;
  border: 2px solid red;

  .chapterdetailItem {
    margin-bottom: 10px;
    font-size: 14px;
    .chapteritemKey {
        font-weight: bold;
        color: gray;
        margin-right: 5px;
      }

      .chapteritemValue {
        font-weight: 300;
      }
  }
}
.chapterviewButton {
    padding: 2px 5px;
    // margin: 2px 40px 2px 40px;
    border-radius: 5px;
    text-align: center;
    color: darkblue;
    border: 1px dotted rgba(0, 0, 139, 0.596);
    cursor: pointer;
  }
  
  .chapterdeleteButton {
    padding: 2px 5px;
    // margin: 2px 40px 2px 40px;
    text-align: center;
    border-radius: 5px;
    color: crimson;
    border: 1px dotted rgba(220, 20, 60, 0.6);
    cursor: pointer;
  }