.single {
  display: flex;
  width: 100%;
  .singleContainer {
    flex: 6;

    .top {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .left {
        flex: 1;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        position: relative;

        .editButton {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          font-size: 12px;
          color: #7451f8;
          background-color: #7551f818;
          cursor: pointer;
          border-radius: 0px 0px 0px 5px;
        }

        .item {
          display: flex;
          gap: 20px;

          .itemImg {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }

          .details {
            .itemTitle {
              margin-bottom: 10px;
              color: #555;
            }

            .detailItem {
              margin-bottom: 10px;
              font-size: 14px;

              .itemKey {
                font-weight: bold;
                color: gray;
                margin-right: 5px;
              }

              .itemValue {
                font-weight: 300;
              }
            }
          }
        }
      }

      .right {
        flex: 2;
      }
    }

    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 10px 20px;
    }

    .title {
      font-size: 16px;
      color: lightgray;
      margin-bottom: 20px;
    }
  }
}
.editInput {
  height: 13px;
  padding: 6px;
  margin: 5px 0px;
}
.link {
  text-decoration: none;
  color: green;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid green;
  padding: 5px;
  border-radius: 5px;
  /* max-height: max-content; */
  cursor: pointer;
  height: max-content;
}
.options {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.topicquestions {
  box-shadow: 0px 0px 11px 2px rgba(201, 201, 206, 20.47);
  border: 2px solid #8f8f8f;
  margin: 1rem 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.questioncontainer {
  border: 2px solid #8f8f8f;
  display: flex;
  flex-direction: column;
  // width: max-content;
  // width: 25rem;
  margin: 0.5rem 0.5rem;
  padding: 0.5rem 0.5rem;
  border-radius: 0.6rem;
}
.bold {
  font-weight: bold;
}
.questionbtn {
  width: max-content;
  margin: 1rem 0rem;
  padding: 0.5rem 1rem;
  border: 1px dotted green;
  cursor: pointer;
}
.question {
  padding: 1rem 0.4rem;
}
.flexcontainer {
  display: flex;
  gap: 1rem;
}
.delete-btn {
  color: crimson;
  border: 1px dotted rgba(220, 20, 60, 0.6);
  cursor: pointer;
}
